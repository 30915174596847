import { useState } from "react";
import {
  Table,
  Form,
  Row,
  Col,
  Label,
  Input,
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "reactstrap";
import request from "../../utils/request";
import "../../styles/SearchTransactionHistory.css";
import Cookies from "js-cookie";

const SearchTransactionHistory = () => {
  const [searchKey, setSearchKey] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [noResultsFound, setNoResultsFound] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [server, setServer] = useState("server1");

  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  let userData = null;
  try {
    const userDataString = Cookies.get("USER_DATA");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    console.error("Error parsing ACCOUNT_DATA:", error);
  }

  const handleServerSelect = (server) => {
    setServer(server);
  };

  const handleSearch = async (e) => {
    e.preventDefault(); // Ngăn form submit làm refresh trang
    try {
      const response = await request.get(
        `TransactionsHistory?key=${searchKey}&server=${userData.server}}`
      );
      if (response.data.length === 0) {
        // Kiểm tra nếu không có kết quả
        setNoResultsFound(true);
        setTransactions([]);
      } else {
        setNoResultsFound(false);
        setTransactions(response.data); // Cập nhật state với dữ liệu nhận được
      }
    } catch (error) {
      console.error("Error fetching data: ", error);
      setTransactions([]); // Xử lý trường hợp lỗi
      setNoResultsFound(true);
    }
  };

  const formatDate = (date) => {
    // Định dạng ngày tháng và thời gian
    // PadStart được sử dụng để đảm bảo rằng mỗi phần tử là 2 chữ số
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // getMonth trả về 0-11 nên cần +1
    const year = date.getFullYear();

    // Kết hợp các phần tử lại với nhau để tạo ra chuỗi định dạng cuối cùng
    return `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;
  };

  return (
    <div style={{ backgroundColor: "#4d5154" }}>
      <Form
        style={{
          paddingTop: "20px",
          marginBottom: "20px",
          display: "flex",
          alignItems: "center",
        }}
        onSubmit={handleSearch}
      >
        <Row className="row-cols-lg-auto g-3 align-items-center">
          <Col>
            <Label className="visually-hidden" for="exampleEmail">
              Tên
            </Label>
            <Input
              id="searchInput"
              name="search"
              placeholder="Tìm kiếm"
              type="text"
              value={searchKey}
              onChange={(e) => setSearchKey(e.target.value)}
            />
          </Col>
          <Col>
            <Dropdown isOpen={dropdownOpen} toggle={toggleDropdown}>
              <DropdownToggle caret>
                {server === "server1" ? "Server 1" : "Server 2"}
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => handleServerSelect("server1")}>Server 1</DropdownItem>
                <DropdownItem onClick={() => handleServerSelect("server2")}>Server 2</DropdownItem>
                <DropdownItem onClick={() => handleServerSelect("server3")}>Server 3</DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </Col>
          <Col>
            <Button type="submit">Nhấp</Button>
          </Col>
        </Row>
      </Form>
      {noResultsFound && (
        <div
          style={{ color: "white", textAlign: "center", marginBottom: "10px" }}
        >
          <h6>Không tìm thấy dữ liệu cho từ khóa: "{searchKey}"</h6>
        </div>
      )}
      <Table dark>
        <thead>
          <tr>
            <th>#</th>
            <th>Player 1</th>
            <th>Player 2</th>
            <th>Item Player 1</th>
            <th>Item Player 2</th>
            <th>Player 1 Before Trans</th>
            <th>Player 2 Before Trans</th>
            <th>Player 1 After Trans</th>
            <th>Player 2 After Trans</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
          {transactions.map((transaction, index) => (
            <tr key={index}>
              <th scope="row">{index + 1}</th>
              <td>{transaction.player1}</td>
              <td>{transaction.player2}</td>
              <td>{transaction.itemPlayer1}</td>
              <td>{transaction.itemPlayer2}</td>
              <td>{transaction.bag1BeforeTran}</td>
              <td>{transaction.bag2BeforeTran}</td>
              <td>{transaction.bag1AfterTran}</td>
              <td>{transaction.bag2AfterTran}</td>
              <td>{formatDate(new Date(transaction.timeTran))}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};
export default SearchTransactionHistory;
