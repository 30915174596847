import React, { useRef, useState } from "react";
import "../../styles/register.css";
import { Link, useNavigate } from "react-router-dom";
import request from "../../utils/request";
import NotificationAlert from "react-notification-alert";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [server, setServer] = useState("server1");
  const [confirmPassword, setConfirmPassword] = useState("");
  const key = "6Lfkk7cpAAAAAIzmL-B2lUDNEcTXd_brvhDmMrfV";
  const notificationAlertRef = useRef(null);
  const recaptchaRef = useRef(null);

  const navigate = useNavigate();

  const notifySuccess = () => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Đăng ký thành công!
          </span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const notifyDanger = (message) => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {message.title}
          </span>
          <br />
          <span data-notify="message">{message.details}</span>
        </div>
      ),
      type: "danger",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleCheckUsername = async () => {
    try {
      const response = await request.get(
        `/Auth/CheckUsername?username=${username}&server=${server}`
      );
      if (response.status === 200) {
        notifyDanger({
          title: "Tên người dùng đã tồn tại!",
          details: `Tên người dùng '${username}' đã được sử dụng. Vui lòng chọn một tên khác.`,
        });
        return false; // Ngăn không cho tiếp tục đăng ký
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // Tên người dùng không tồn tại, có thể tiếp tục đăng ký
        return true;
      } else {
        // Xử lý lỗi khác
        notifyDanger({
          title: "Lỗi!",
          details:
            "Có lỗi xảy ra khi kiểm tra tên người dùng. Vui lòng thử lại sau.",
        });
        return false; // Trong trường hợp lỗi, ngăn không cho tiếp tục đăng ký
      }
    }
  };

  const handleRegister = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const captchaToken = recaptchaRef.current.getValue(); // Lấy token từ reCAPTCHA
    if (!captchaToken) {
      // Xử lý trường hợp reCAPTCHA chưa được hoàn thành
      return;
    }

    const isUsernameAvailable = await handleCheckUsername();
    if (!isUsernameAvailable) return;

    if (password !== confirmPassword) {
      notifyDanger({
        title: "Mật khẩu mới và xác nhận mật khẩu mới không khớp!",
        details: `Vui lòng kiểm tra các trường đã điền`,
      });
      return;
    }

    // Kiểm tra mật khẩu có trên 6 ký tự và không chứa dấu cách
    if (password.length < 6 || password.includes(" ")) {
      notifyDanger({
        title: "Mật khẩu không hợp lệ!",
        details: `Mật khẩu phải dài hơn 6 ký tự và không chứa dấu cách.`,
      });
      return;
    }

    try {
      const response = await request.post("/Auth/AuthRegister", {
        username: username,
        password: password,
        captchaToken: captchaToken,
        server: server,
      });

      if (response.status === 200) {
        notifySuccess();
        navigate("/login");
      } else {
        notifyDanger({
          title: "Đăng ký thất bại.",
          details: `Vui lòng kiểm tra các trường đã điền`,
        });
      }
    } catch (error) {
      console.error("Đăng ký thất bại:", error);
      notifyDanger({
        title: "Đăng ký thất bại.",
        details: `Vui lòng kiểm tra các trường đã điền`,
      });
    }
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="register-container">
        <div className="form-register">
          <h1>ĐĂNG KÝ</h1>
          <form onSubmit={handleRegister}>
            <div className="form">
              <label>Tài khoản</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nhập tên tài khoản"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form">
              <label>Mật khẩu</label>
              <input
                type="password"
                className="form-control"
                placeholder="Mật khẩu"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form">
              <label>Xác nhận mật khẩu</label>
              <input
                type="password"
                className="form-control"
                placeholder="Xác nhận mật khẩu"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <div className="form">
              <label>Server</label>
              <select
                className="form-control"
                value={server}
                onChange={(e) => setServer(e.target.value)}
                required
              >
                <option value="server1">Server 1</option>
                <option value="server2">Server 2</option>
                <option value="server3">Server 3</option>
              </select>
            </div>
            <div className="link">
              <Link to={"/login"}>Đăng nhập</Link>
              <Link to={"/forgot-password"}>Quên mật khẩu</Link>
            </div>
            <ReCAPTCHA sitekey={key} ref={recaptchaRef} />
            <button type="submit">Đăng ký</button>
          </form>
        </div>
      </div>
    </>
  );
};
export default Register;
