import React, { useRef, useState } from "react";
import "../../styles/login.css";
import { Link, useNavigate } from "react-router-dom";
import request from "../../utils/request";
import NotificationAlert from "react-notification-alert";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";

const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [server, setServer] = useState("server1"); 
  const navigate = useNavigate(); // useNavigate hook for navigation
  const notificationAlertRef = useRef(null);
  const key = "6Lfkk7cpAAAAAIzmL-B2lUDNEcTXd_brvhDmMrfV";
  const recaptchaRef = useRef(null);

  const notifySuccess = () => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Đăng nhập thành công.
          </span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const notifyDanger = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            Đăng nhập không thành công!{" "}
          </span>
          <br />
          <span data-notify="message">
            Vui lòng kiểm tra các trường đã điền
          </span>
        </div>
      ),
      type: "danger",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleLogin = async (e) => {
    e.preventDefault(); // Prevent default form submission behavior

    const captchaToken = recaptchaRef.current.getValue(); // Lấy token từ reCAPTCHA
    if (!captchaToken) {
      // Xử lý trường hợp reCAPTCHA chưa được hoàn thành
      return;
    }

    try {
      // Using the `request.post` method similar to the register function
      const response = await request.post("/Auth/AuthLogin", {
        Username: username,
        Password: password.toLowerCase(),
        captchaToken: captchaToken,
        server: server, 
      });

      // Assuming `request.post` returns a promise similar to the Fetch API
      if (response.status === 200) {
        notifySuccess();

        let currentDate = new Date();
        // Thêm 2 tiếng vào thời điểm hiện tại
        currentDate.setTime(currentDate.getTime() + 2 * 60 * 60 * 1000);

        Cookies.set("USER_DATA", JSON.stringify(response.data.userData), {
          expires: currentDate,
        });
        navigate("/");
        window.location.reload();
      } else {
        notifyDanger();
      }
    } catch (error) {
      console.error("Đăng nhập thất bại:", error);
      notifyDanger();
    }
  };
  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="login-container">
        <div className="form-login">
          <h1>ĐĂNG NHẬP</h1>
          <form onSubmit={handleLogin}>
            <div className="form">
              <label>Tài khoản</label>
              <input
                type="text"
                className="form-control"
                placeholder="Nhập tên tài khoản"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
            </div>
            <div className="form">
              <label>Mật khẩu</label>
              <input
                type="password"
                className="form-control"
                placeholder="Mật khẩu"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form">
              <label>Server</label>
              <select
                className="form-control"
                value={server}
                onChange={(e) => setServer(e.target.value)}
                required
              >
                <option value="server1">Server 1</option>
                <option value="server2">Server 2</option>
                <option value="server3">Server 3</option>
              </select>
            </div>
            <div className="link">
              <Link to={"/register"}>Đăng ký</Link>
              <Link to={"/forgot-password"}>Quên mật khẩu</Link>
            </div>
            <ReCAPTCHA sitekey={key} ref={recaptchaRef} />
            <button type="submit">Đăng nhập</button>
          </form>
        </div>
      </div>
    </>
  );
};
export default Login;
