import Cookies from "js-cookie";
import { useRef, useState } from "react";
import NotificationAlert from "react-notification-alert";
import { Button, Form, FormGroup, Input, Label } from "reactstrap";
import "../../styles/charge.css";
import request from "../../utils/request";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import Information from "../auth/Infomation";

const Charge = () => {
  const notificationAlertRef = useRef(null);
  const navigate = useNavigate();
  const [cardInfo, setCardInfo] = useState({
    telco: "",
    code: "",
    serial: "",
    amount: "",
  });
  const key = "6Lfkk7cpAAAAAIzmL-B2lUDNEcTXd_brvhDmMrfV";
  const recaptchaRef = useRef(null);

  let userData = null;
  try {
    const userDataString = Cookies.get("USER_DATA");
    if (userDataString) {
      userData = JSON.parse(userDataString);
    }
  } catch (error) {
    console.error("Error parsing ACCOUNT_DATA:", error);
  }

  const notifySuccess = () => {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Xin vui lòng chờ để hệ thống xử lý thông tin thẻ.
          </span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const notifyDanger = () => {
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            Thông tin thẻ không chính xác!{" "}
          </span>
          <br />
          <span data-notify="message">Vui lòng kiểm tra lại</span>
        </div>
      ),
      type: "danger",
      icon: "ni ni-bell-55",
      autoDismiss: 7,
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleChange = (e) => {
    const { name, value } = e.target; // Lấy name và value từ input field đang được thay đổi

    // Cập nhật state cardInfo với giá trị mới từ input field
    setCardInfo((prevState) => ({
      ...prevState,
      [name]: value, // Sử dụng computed property names để cập nhật key tương ứng
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const captchaToken = recaptchaRef.current.getValue(); // Lấy token từ reCAPTCHA
    if (!captchaToken) {
      // Xử lý trường hợp reCAPTCHA chưa được hoàn thành
      return;
    }

    try {
      const formData = new FormData();
      const { telco, code, serial, amount } = cardInfo;

      formData.append("telco", telco);
      formData.append("code", code.replace(/\s+/g, ""));
      formData.append("serial", serial.replace(/\s+/g, ""));
      formData.append("amount", amount.toString());
      formData.append("accountId", userData.id);

      let response = null;
      if (userData.server === "server2") {
        response = await request.post("/Charge/SendCard2", formData);
      } else if (userData.server === "server3") {
        response = await request.post("/Charge/SendCard3", formData);
      } else {
        response = await request.post("/Charge/SendCard", formData);
      }

      if (response.data) {
        navigate("/payment-history");
        notifySuccess();
      } else {
        notifyDanger();
      }
    } catch (error) {
      console.error("Error when sending card:", error);
    }
  };

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlertRef} />
      </div>
      <div className="charge">
        <Information />
        <h1>NẠP TIỀN</h1>
        <Form className="form-charge" onSubmit={handleSubmit}>
          <FormGroup>
            <Label for="name">Loại thẻ:</Label>
            <Input
              id="exampleSelect"
              name="telco"
              type="select"
              onChange={handleChange}
              required
            >
              <option value="">Chọn loại thẻ</option>
              <option value="VIETTEL">Viettel</option>
              <option value="VINAPHONE">Vinaphone</option>
              <option value="MOBIFONE">Mobifone</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="examplePassword">Mã thẻ:</Label>
            <Input
              id="exampleText"
              name="code"
              type="text"
              placeholder="Mã thẻ"
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Seri thẻ:</Label>
            <Input
              id="exampleText"
              name="serial"
              type="text"
              placeholder="Seri thẻ"
              onChange={handleChange}
              required
            />
          </FormGroup>
          <FormGroup>
            <Label for="exampleText">Mệnh giá thẻ:</Label>
            <Input
              id="exampleSelect"
              name="amount"
              onChange={handleChange}
              type="select"
              required
            >
              <option value="">Chọn mệnh giá thẻ</option>
              <option value="10000">10,000 VNĐ</option>
              <option value="20000">20,000 VNĐ</option>
              <option value="50000">50,000 VNĐ</option>
              <option value="100000">100,000 VNĐ</option>
              <option value="200000">200,000 VNĐ</option>
              <option value="300000">300,000 VNĐ</option>
              <option value="500000">500,000 VNĐ</option>
              <option value="1000000">1,000,000 VNĐ</option>
            </Input>
          </FormGroup>
          <ReCAPTCHA sitekey={key} ref={recaptchaRef} />

          <i style={{ textAlign: "start" }}>
            <b>Lưu ý:</b> Chọn sai mệnh giá sẽ bị trừ 50% giá trị thẻ nạp!
            <br />
            Nhập sai thẻ quá 5 lần liên tiếp sẽ bị khóa tài khoản
          </i>
          <div className="form-button">
            <Button type="submit" className="btn btn-success">
              Gửi thẻ
            </Button>
          </div>
        </Form>
      </div>
    </>
  );
};
export default Charge;
